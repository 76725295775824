<template>
  <div class="base-success">
    <div class="content-sucess">
      <span>提交成功</span>
    </div>
    <div class="content-code">
      <img class="code-img" src="@/assets/images/garage/code.png" alt="@/assets/images/garage/code.png">
    </div>
    <div class="content-down-msg">
      <div class="down-msg">扫一扫下载大大汽修APP</div>
      <div class="msg">工作人员将在一个工作日内审核</div>
      <div class="msg">并短信通知您审核结果</div>
    </div>

    <div class="foot-content">
      <cube-button class="foot-content-button" type="submit" @click="submitted">完善信息</cube-button>
    </div>

    <div class="content-down-msg">
      <div class="last-msg">为了更好的为您提供服务，请完善信息</div>
    </div>

    <div class="foot-line-view"></div>

    <div class="foot-view">
      <span class="normal-msg">联系客服:</span>
      <span class="select-msg">400-921-8869</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      statue: false
    }
  },
  methods: {
    submitted () {
      console.log('========submitted=========')
      this.$router.push({
        name: '完善信息'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.base-success{
  width:100%;
  // height:100vh;
  background:rgba(255,255,255,1);
}
.content-sucess{
  height:px2rem(50px);
  font-size:px2rem(36px);
  font-family:PingFangSC-Semibold;
  font-weight:600;
  color:rgba(54,54,54,1);
  line-height:px2rem(50px);
  text-align: center;
  margin-top: px2rem(99px);
}
.content-code{
  width:100%;
  text-align: center;
  margin-top: px2rem(30px);
  .code-img{
    width: px2rem(379px);
    height:px2rem(382px);
  }
}
.content-down-msg{
  margin-top: px2rem(22px);
  text-align: center;
  .down-msg{
    height:px2rem(36px);
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(54,54,54,1);
    line-height:px2rem(36px);
  }
  .msg{
    margin-top: px2rem(10px);
    font-size:px2rem(24px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(153,153,153,1);
  }
  .last-msg{
    margin-top: px2rem(4px);
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(153,153,153,1);
  }
}
.foot-content{
  display: flex;
  justify-content: center;
  margin-top: px2rem(93px);
  .foot-content-button{
    width: px2rem(482px);
    height: px2rem(90px);
  }
}
.foot-line-view{
  width:px2rem(630px);
  height:px2rem(2px);
  background:rgba(228,228,228,1);
  margin-left: px2rem(60px);
  position: absolute;
  bottom: px2rem(132px);
}
.foot-view{
  left: px2rem(207px);
  position: absolute;
  bottom: px2rem(50px);
  .normal-msg{
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(153,153,153,1);
  }
  .select-msg{
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:#4081D6;
  }
}
</style>
